import { Grid, Typography } from '@mui/material';
import { CustomBreadcrumbs } from '../../../components/CustomBreadcrumbs';
import { CustomSteps } from '../../../components/CustomSteps';
import { CustomStepper } from '../../../components/CustomStepper';
import { AnalyticalStepTwoState } from '../../../context/Analytical/StepTwo/AnalyticalStepTwoState';
import { AnalyticalStepTwoForm } from './AnalyticalStepTwoForm';
import { AnalyticalStepTwoContext } from '../../../context/Analytical/AnalyticalContext';
import { PreviousDataComponent } from '../../../components/PreviousDataComponent';
import { stepsArray } from '../../../constants/StepsArray';
import { AnalyticRouteConstants } from '../../../routes/RoutesConstants';
import { useAuthHelper } from '../../../hooks/useAuthHelper';
import { useContext } from 'react';
import { AppContext } from '../../../context/global/AppContext';

export const AnalyticalStepTwoPage = () => {
  const { isUserAdminOrLegalization } = useAuthHelper();
  const { getEstablishmentData } = useContext(AppContext);
  const { establishmentId, establishmentName } = getEstablishmentData(isUserAdminOrLegalization());
  const baseLink = isUserAdminOrLegalization()
    ? AnalyticRouteConstants.BUSCAR_PARAMS(establishmentId, establishmentName)
    : AnalyticRouteConstants.BUSCAR;
  return (
    <AnalyticalStepTwoState>
      <Grid sx={{ background: 'linear-gradient(180deg, rgba(230,235,240,1) 30%, rgba(243,246,249,1) 30%)', p: '40px' }}>
        <br />
        <CustomBreadcrumbs base={'Analítico'} now={'Editar analítico'} baseLink={baseLink} />
        <Typography variant="h3" color="initial">
          Plan de estudios
        </Typography>
        <br />
        <CustomSteps activeStep={2} stepsArray={stepsArray} />
        <CustomStepper steps={3} activeStep={2} />
        <br />
        <AnalyticalStepTwoContext.Consumer>
          {(ctx) => {
            return ctx.previewInfo ? (
              <PreviousDataComponent
                name={`${ctx.previewInfo.name} ${ctx.previewInfo.last_name}`}
                docType={ctx.previewInfo.document_type.name}
                document={ctx.previewInfo.document_number}
              />
            ) : null;
          }}
        </AnalyticalStepTwoContext.Consumer>
        <AnalyticalStepTwoForm />
      </Grid>
    </AnalyticalStepTwoState>
  );
};
