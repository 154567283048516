import moment from 'moment';
import { useEffect, useState, useContext } from 'react';
import { useParams } from 'react-router-dom';
import {
  getCopies,
  getAnalyticCopiesReasons,
  getStepTwoAnalyticInfo,
  getStudentByAnalyticalId,
  getStudyPlansByEstablishment,
  patchStudentStepTwo,
  getEstablishmentNames,
  getAnalyticCopyOriginalReasons,
  getStudyPlanStatus
} from '../../../services/AnalyticalServices';
import { AppContext } from '../../global/AppContext';
import { AnalyticalStepTwoContext } from '../AnalyticalContext';
import { useAuthHelper } from '../../../hooks/useAuthHelper';

const ORIGINAL = 1;
const PARCIALES = 2;

export const AnalyticalStepTwoState = ({ children }) => {
  //objeto para preview
  const [previewInfo, setPreviewInfo] = useState(null);
  const [options, setOptions] = useState({ studyPlans: [], copies: [] });
  const [stepTwoInfo, setStepTwoInfo] = useState();
  const [serialN, setSerialN] = useState();
  const [formN, setFormN] = useState();
  const [justifyText, setJustifyText] = useState();
  const [denunciationN, setDenunciationN] = useState();
  const { getEstablishmentData } = useContext(AppContext);
  const [yesOrNotDelivered, setYesOrNotDelivered] = useState('');
  const [copieReasonSelected, setCopieReasonSelected] = useState();
  const [establishments, setEstablisments] = useState();
  const [establishmentAutocompleteSelected, setEstablishmentAutocompleteSelected] = useState();
  const [studyPlanLinkStatus, setStudyPlanLinkStatus] = useState('');
  const { isUserAdminOrLegalization } = useAuthHelper();
  const { establishmentId } = getEstablishmentData(isUserAdminOrLegalization());

  useEffect(() => {
    getStudyPlansByEstablishmentData();
    getStudentByAnalyticalIdData(params.id);
    getAllCopies();
    getAnalyticCopiesReasonsData();
    getAnalyticCopyOriginalReasonsData();
  }, []);

  useEffect(() => {
    if (options.studyPlans.length > 0) getStepTwoInfo(params.id);
  }, [options.studyPlans]);

  let params = useParams();

  const getStudentByAnalyticalIdData = async (id) => {
    let res = await getStudentByAnalyticalId(id);
    const data = res.analytic_student;
    setPreviewInfo(data);
  };

  const getEstablishmentNamesData = async () => {
    let res = await getEstablishmentNames();
    setEstablisments(res.filter((e) => e.id !== establishmentId));
  };

  const getAnalyticCopiesReasonsData = async () => {
    let res = await getAnalyticCopiesReasons();
    let data = res.map((e) => {
      e.name = e.reason;
      e.id_reason = e.id;
      return e;
    });
    setOptions((prevState) => {
      return { ...prevState, copiesReasons: data };
    });
  };

  const getAnalyticCopyOriginalReasonsData = async () => {
    let res = await getAnalyticCopyOriginalReasons();
    let data = res.map((e) => {
      e.name = e.reason;
      e.id_reason = e.id;
      return e;
    });
    setOptions((prevState) => {
      return { ...prevState, copyOriginalReasons: data };
    });
  };

  const getStudyPlansByEstablishmentData = async () => {
    let res = await getStudyPlansByEstablishment(establishmentId);
    let data = res.map((r) => {
      r.name = `${r.study_plan_name} ${r.amending_resolution && r.amending_resolution != 'null' ? `(${r.amending_resolution})` : ''}`;
      r.studyPlanId = r.id;
      return r;
    });

    setOptions((prevState) => {
      return { ...prevState, studyPlans: data };
    });
  };

  const getStepTwoInfo = async (analyticId) => {
    let res = await getStepTwoAnalyticInfo(analyticId);
    res.data.type_id = res.data.analytic_types ? res.data.analytic_types.id : '';
    res.data.copies = res.data.analytic_copy ? res.data.analytic_copy.id : '';
    res.data.studyPlanId = res.data.study_plan_id;
    res.data.title = options.studyPlans.filter((x) => x.id == res.data.study_plan_id)[0]?.title;
    res.data.egress_date = res.data.egress_date ? moment(res.data.egress_date) : null;
    res.data.broadcast_date = res.data.broadcast_date ? moment(res.data.broadcast_date) : null;

    setStepTwoInfo(res.data);
  };

  const patchStudentStepTwoData = async (body) => {
    body.analyticId = parseInt(params.id);
    body.delivered = yesOrNotDelivered;
    delete body.title;
    if (body.type_id > PARCIALES) {
      body.is_copy_id = copieReasonSelected;
      body.report_number = denunciationN;
    }
    body.partial_studies_establishment_id =
      establishmentAutocompleteSelected && body.type_id === PARCIALES ? establishmentAutocompleteSelected.id : null;
    if (copieReasonSelected && body.type_id === ORIGINAL) {
      body.is_original_copy_id = copieReasonSelected;
    }
    // if (yesOrNotDelivered) {
    //     body.serial_number = serialN
    //     body.form_number = formN
    // }
    let res = await patchStudentStepTwo(body);
    return res;
  };

  const getAllCopies = async () => {
    let data = await getCopies();
    setOptions((prevState) => {
      return { ...prevState, copies: data };
    });
  };

  const handleOriginalDelivered = (event) => {
    setYesOrNotDelivered(event.target.value);
  };

  const getStudyPlanLinkStatus = async (studentId, studyPlanId) => {
    const status = await getStudyPlanStatus(studentId, studyPlanId);
    setStudyPlanLinkStatus(status);
    return status;
  };

  return (
    <AnalyticalStepTwoContext.Provider
      value={{
        previewInfo,
        options,
        stepTwoInfo,
        patchStudentStepTwoData,
        setStepTwoInfo,
        serialN,
        setSerialN,
        formN,
        setFormN,
        denunciationN,
        setDenunciationN,
        justifyText,
        setJustifyText,
        handleOriginalDelivered,
        yesOrNotDelivered,
        copieReasonSelected,
        setCopieReasonSelected,
        establishments,
        getEstablishmentNamesData,
        setEstablishmentAutocompleteSelected,
        getStudyPlanLinkStatus,
        studyPlanLinkStatus
      }}>
      {children}
    </AnalyticalStepTwoContext.Provider>
  );
};
